import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import $ from 'jquery';
import {
  ButtonGroup, Button, Modal, Table,
} from 'react-bootstrap';

import PortalWrapper from '../helpers/PortalWrapper';

class BatchActionsToolbar extends Component {
  constructor(props) {
    super(props);

    this.checkboxes = this.buildCheckboxes();
    this.storageKey = `batchSelect/${props.storageKey}`;

    this.state = {
      showModal: false,
      checkedItems: this.loadStorage(),
    };

    this.state.allSelected = this.allVisibleSelected();
  }

  componentDidMount() {
    this.saveStorage();
  }

  handleSelect = (event) => {
    const item = event.target.name;
    const isChecked = event.target.checked;

    this.setState((prevState) => {
      if (isChecked) prevState.checkedItems.set(item, this.checkboxes.get(item).title);
      else prevState.checkedItems.delete(item);

      return prevState;
    }, this.saveStorage);
  }

  handleSelectAll = () => {
    const { allSelected } = this.state;

    this.setState((prevState) => {
      this.checkboxes.forEach((checkbox, key) => {
        if (!allSelected && !checkbox.disabled) prevState.checkedItems.set(key, checkbox.title);
        else prevState.checkedItems.delete(key);
      });

      return prevState.checkedItems;
    }, this.saveStorage);
  }

  handleRemoveItem = (key) => {
    this.setState((prevState) => {
      prevState.checkedItems.delete(key);
      return ({ checkedItems: prevState.checkedItems });
    }, this.saveStorage);
  }

  handleResetSelection = () => {
    this.setState({
      checkedItems: new Map(),
    }, this.saveStorage);
  }

  handleModalClose = () => {
    this.setState({ showModal: false });
  }

  handleModalShow = () => {
    this.setState({ showModal: true });
  }

  loadStorage() {
    const { visibleOnly } = this.props;

    try {
      const items = window.localStorage.getItem(this.storageKey);

      if (typeof items === 'string') {
        const storedItems = new Map(JSON.parse(items));

        if (visibleOnly) {
          storedItems.forEach((_, index) => {
            if (!this.checkboxes.has(index)) {
              storedItems.delete(index);
            }
          });
        }

        return storedItems;
      }
    } catch (error) {
      // Swallow
    }

    return new Map();
  }

  saveStorage() {
    const { checkedItems } = this.state;
    const { onChange } = this.props;

    localStorage.setItem(this.storageKey, JSON.stringify([...checkedItems]));

    this.setState({
      allSelected: this.allVisibleSelected(),
    });

    onChange(checkedItems);
  }

  buildCheckboxes() {
    const { selector } = this.props;

    return new Map(
      Array.from($(selector).find('[batch-item]'), (item) => {
        const key = item.getAttribute('batch-item');
        const result = {
          key,
          element: item,
          disabled: item.hasAttribute('batch-disabled'),
          title: item.getAttribute('batch-title'),
        };

        if (item.hasAttribute('batch-props')) {
          result.props = JSON.parse(item.getAttribute('batch-props'));
        }

        return [key, result];
      }),
    );
  }

  allVisibleSelected() {
    const { checkedItems } = this.state;

    for (const [key, checkbox] of this.checkboxes) if (checkedItems.get(key) === undefined) return false;

    return true;
  }

  renderCheckboxesElements() {
    const { checkedItems } = this.state;

    return Array.from(this.checkboxes, ([key, checkbox]) => (
      <PortalWrapper node={checkbox.element} key={key}>
        <input
          type="checkbox"
          name={key}
          disabled={checkbox.disabled}
          checked={checkedItems.get(key) !== undefined}
          onChange={this.handleSelect}
        />
        &nbsp;
      </PortalWrapper>
    ));
  }

  renderCheckboxesSelectAll() {
    const { selector } = this.props;
    const { allSelected } = this.state;
    const selectAllNode = $(selector).find('[batch-select-all]')[0];

    if (selectAllNode) {
      return (
        <PortalWrapper node={selectAllNode}>
          <input
            type="checkbox"
            checked={allSelected}
            onChange={this.handleSelectAll}
          />
          &nbsp;
        </PortalWrapper>
      );
    }

    return null;
  }

  renderItemsTable() {
    const { checkedItems } = this.state;
    const itemsList = Array.from(checkedItems, ([key, title]) => (
      <tr key={key}>
        <td>{key}</td>
        <td>{title}</td>
        <td><Button bsStyle="danger" bsSize="xsmall" onClick={() => this.handleRemoveItem(key)}>Remove</Button></td>
      </tr>
    ));

    return (
      <Table striped bordered condensed>
        <thead>
          <tr>
            <th>ID</th>
            <th>Name</th>
            <th>&nbsp;</th>
          </tr>
        </thead>
        <tbody>
          {itemsList}
        </tbody>
      </Table>
    );
  }

  renderModal() {
    const { showModal, checkedItems } = this.state;
    const { children } = this.props;

    return (
      <Modal show={showModal} onHide={this.handleModalClose}>
        <Modal.Header closeButton>
          <Modal.Title>Selected items</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {checkedItems.size > 0 ? this.renderItemsTable() : 'No items selected'}
        </Modal.Body>
        <Modal.Footer>
          <ButtonGroup>
            {children}
          </ButtonGroup>
          &nbsp;
          <Button bsStyle="danger" onClick={this.handleResetSelection}>Clear Selection</Button>
          <Button onClick={this.handleModalClose}>Close</Button>
        </Modal.Footer>
      </Modal>
    );
  }

  render() {
    const { children } = this.props;
    const { checkedItems } = this.state;

    return (
      <Fragment>
        <ButtonGroup>
          {children}
          <Button onClick={this.handleModalShow}>
            {checkedItems.size}
            &nbsp;Items selected
          </Button>
        </ButtonGroup>
        {this.renderModal()}
        {this.renderCheckboxesElements()}
        {this.renderCheckboxesSelectAll()}
      </Fragment>
    );
  }
}

BatchActionsToolbar.propTypes = {
  storageKey: PropTypes.string.isRequired,
  selector: PropTypes.string.isRequired,
  onChange: PropTypes.func,
  children: PropTypes.node,
  visibleOnly: PropTypes.bool,
};

BatchActionsToolbar.defaultProps = {
  onChange: () => {},
  children: [],
  visibleOnly: false,
};

export default BatchActionsToolbar;
