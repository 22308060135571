import React from 'react';
import PropTypes from 'prop-types';

import {
  Col,
  FormGroup,
  FormControl,
  ControlLabel,
  HelpBlock,
} from 'react-bootstrap';

class FieldGroup extends React.Component {
  renderControl = (controlProps) => {
    const { children, renderControl } = this.props;

    if (children) return children;
    if (renderControl) return renderControl(controlProps);

    const { controlId, ...otherProps } = controlProps;

    return (
      <FormControl {...otherProps} />
    );
  }

  renderControlBlock = (nodeId) => {
    const {
      id,
      controlId,
      label,
      help,
      horizontal,
      errorMessage,
      renderControl,
      className,
      ...controlProps
    } = this.props;

    return (
      <>
        {this.renderControl({ controlId: nodeId, ...controlProps })}
        {help && <HelpBlock>{help}</HelpBlock>}
        {errorMessage && <HelpBlock>{errorMessage}</HelpBlock>}
      </>
    );
  }

  render() {
    const {
      id,
      controlId,
      label,
      horizontal,
      errorMessage,
      className,
    } = this.props;

    const validationState = errorMessage ? 'error' : null;
    const nodeId = id || controlId;

    return (
      <FormGroup controlId={nodeId} className={className} validationState={validationState}>
        {label && (horizontal
          ? <Col componentClass={ControlLabel} htmlFor={nodeId} sm={3} lg={2}>{label}</Col>
          : <ControlLabel htmlFor={nodeId}>{label}</ControlLabel>)}
        {horizontal ? <Col sm={6} lg={4}>{this.renderControlBlock(nodeId)}</Col> : this.renderControlBlock(nodeId)}
      </FormGroup>
    );
  }
}

FieldGroup.propTypes = {
  id: PropTypes.string,
  controlId: PropTypes.string,
  label: PropTypes.string,
  help: PropTypes.string,
  errorMessage: PropTypes.string,
  horizontal: PropTypes.bool,
  className: PropTypes.string,
  renderControl: PropTypes.func,
  children: PropTypes.node,
};

FieldGroup.defaultProps = {
  id: null,
  controlId: null,
  label: null,
  help: null,
  errorMessage: null,
  horizontal: false,
  className: null,
  renderControl: null,
  children: null,
};

export default FieldGroup;
