import React from 'react';
import ReactDOM from 'react-dom';

class PortalWrapper extends React.Component {
  render() {
    const { children, node } = this.props;

    return ReactDOM.createPortal(
      children,
      node,
    );
  }
}

export default PortalWrapper;
