import React from 'react';
import { Provider } from 'mobx-react';
import { adminStore } from 'pm_adminus';

export default class InitAdmin extends React.Component {
  componentWillMount() {
    if (this.props.is_dev) {
      adminStore.setAsDevelopment();
    }
  }

  render() {
    return null;
  }
}
