import $ from 'jquery';
import Base from './base';

class ConstantMessage extends Base {
  events() {
    return {
      'click .toggle-arrow': 'toggleAlert',
    };
  }

  init() {
    super.init();

    const content = $('#constant_message_content');
    this.container.find('.content').html(content.html());
    if (content.data('show')) this.container.removeClass('hidden');
    this.container.addClass(content.data('alertClass'));
    this.container.css('top', $('nav').height());

    $(window).on('scroll', () => this.container.css('top', Math.max(0, $('nav').height() - $(window).scrollTop())));
  }

  toggleAlert(e) {
    e.preventDefault();

    this.container.find('.toggle-arrow').toggleClass('fa-angle-down').toggleClass('fa-angle-up');
    this.container.toggleClass('minimized');
    this.container.find('.content').toggle();
  }

  show() {
    this.container.removeClass('hidden');
  }

  hide() {
    this.container.addClass('hidden');
  }
}

const constantMessageInstance = new ConstantMessage('#constant_message');

export { ConstantMessage };
export default constantMessageInstance;
