import React from 'react';
import PropTypes from 'prop-types';
import { FormControl, ControlLabel } from 'react-bootstrap';

class TimeIntervalInput extends React.Component {
  components = {
    days: 24 * 60 * 60,
    hours: 60 * 60,
    minutes: 60,
    seconds: 1,
  }

  constructor(props) {
    super(props);

    const value = parseInt(props.value, 10);
    let remainder = value;

    const unitValues = {};

    props.units.forEach((unit) => {
      remainder %= this.components[unit];
      unitValues[unit] = Math.floor(remainder / this.components[unit]);
    });

    this.state = {
      value,
      ...unitValues,
    };
  }

  calculateTotalValue = () => {
    const { units } = this.props;

    return units.reduce((total, unit) => total + this.state[unit] * this.components[unit], 0);
  }

  handleUnitChanged = (unit, value) => {
    const { onChange } = this.props;

    const newState = {};
    newState[unit] = parseInt(value, 10) || 0;

    this.setState(newState, () => {
      if (onChange) onChange(this.calculateTotalValue());
    });
  }

  render() {
    const { units } = this.props;

    const inputs = units.map((unit, index) => (
      <React.Fragment key={`time-interval-${unit}`}>
        <FormControl
          type="text"
          value={this.state[unit]}
          onChange={event => this.handleUnitChanged(unit, event.target.value)}
        />
        <ControlLabel>{unit}</ControlLabel>
        {units.length > index + 1 ? ' ' : ''}
      </React.Fragment>
    ));

    return inputs;
  }
}

TimeIntervalInput.propTypes = {
  value: PropTypes.number,
  units: PropTypes.arrayOf(PropTypes.string),
  onChange: PropTypes.func,
};

TimeIntervalInput.defaultProps = {
  value: 0,
  units: ['days', 'hours', 'minutes', 'seconds'],
  onChange: null,
};

export default TimeIntervalInput;
