import $ from 'jquery';
import Notification from 'rc-notification';
import React from 'react';
import { has, castArray } from 'lodash';

const CONTAINER_OPTIONS = {
  style: { top: 120, right: 35 },
  getContainer: () => $('.rc-notifications')[0],
};

let notification = null;
const getNotificationInstance = () => new Promise((resolve, reject) => {
  if (notification) {
    resolve(notification);
  } else {
    Notification.newInstance(CONTAINER_OPTIONS, (n) => {
      notification = n;
      resolve(notification);
    });
  }
});

export const show = (messages, options) => {
  getNotificationInstance().then((notif) => {
    const duration = has(options, 'duration') ? options.duration : 5; // To allow set null duration
    notif.notice({
      content: <div>{castArray(messages).map((message, index) => (<div key={index}>{message}<br/></div>))}</div>,
      duration,
      className: `notif-${options.type}`,
      closable: true,
      style: {},
    });
  });
};

export const success = (messages, options = {}) => {
  show(messages, Object.assign({ type: 'success' }, options));
};

export const error = (messages, options = {}) => {
  show(messages, Object.assign({ type: 'danger' }, options));
};

export default { success, error, show };
