import { observable, action } from 'mobx';

export class AdminStore {
  @observable isDevelopment = false;

  @action setAsDevelopment() {
    this.isDevelopment = true;
  }
}

export default new AdminStore();
