import $ from 'jquery';
import * as _ from 'lodash';

const EVENT_SPLITTER = /^(\S+)\s*(.*)$/;

class Base {
  static events() {
    return {};
  }

  constructor(selector) {
    this._mainSelector = selector;
    $(document).ready(() => this.init());
  }

  init() {
    this.container = $(this._mainSelector);
    this.bindEvents();
  }

  bindEvents() {
    _.each(this.events(), (handler, key) => {
      if (typeof (handler) !== 'function') handler = this[handler];

      const [, eventName, selector] = key.match(EVENT_SPLITTER);

      if (handler) {
        this.container.on(eventName, selector, $.proxy(handler, this));
      }
    });
  }
}

export default Base;
