import React from 'react';
import {
  InputGroup,
  FormControl,
  Button,
  Glyphicon,
} from 'react-bootstrap';
import Datetime from 'react-datetime';

class DateTimePicker extends React.Component {
  constructor(props) {
    super(props);

    this.properties = {
      utc: true,
      dateFormat: 'DD/MM/YYYY',
      timeFormat: 'HH:mm:ss',
      ...props,
    };

    this.state = {
      calendarOpen: false,
    };
  }

  handleOpen = () => {
    this.setState({ calendarOpen: true });
  }

  handleClose = () => {
    this.setState({ calendarOpen: false });
  }

  renderInput = (inputProps, openCalendar, closeCalendar) => {
    const { calendarOpen } = this.state;

    return (
      <InputGroup>
        <FormControl type="text" autoComplete="off" {...inputProps} />
        <InputGroup.Button>
          <Button onClick={calendarOpen ? closeCalendar : openCalendar}>
            <Glyphicon glyph="calendar" />
          </Button>
        </InputGroup.Button>
      </InputGroup>
    );
  };

  render() {
    return (
      <Datetime
        onFocus={this.handleOpen}
        onBlur={this.handleClose}
        renderInput={this.renderInput}
        {...this.properties}
      />
    );
  }
}

export default DateTimePicker;
