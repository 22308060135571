import InitAdmin from './initAdmin';

import DateTimePicker from './presenters/DateTimePicker';
import FieldGroup from './presenters/FieldGroup';
import StaticFieldGroup from './presenters/StaticFieldGroup';
import TimeIntervalInput from './presenters/TimeIntervalInput';

import BatchActionsToolbar from './admin/BatchActionsToolbar';

const components = {
  BatchActionsToolbar,
  FieldGroup,
  InitAdmin,
  StaticFieldGroup,
  TimeIntervalInput,
};

export {
  BatchActionsToolbar,
  DateTimePicker,
  FieldGroup,
  InitAdmin,
  StaticFieldGroup,
  TimeIntervalInput,
};
export default components;
