import axios from 'axios';
import qs from 'qs';
import * as notifications from './notifications';

const csrfToken = document.querySelector('[name="csrf-token"]').content;
const defaultHeaders = {
  'Accept': 'application/json', // eslint-disable-line
  'Content-Type': 'application/json',
  'X-CSRF-Token': csrfToken,
};

const agent = axios.create({
  headers: defaultHeaders,
  paramsSerializer: (params) => qs.stringify(params, { arrayFormat: 'brackets' }),
});

agent.interceptors.response.use((response) => response, (error) => {
  const { response } = error;

  if (response.status === 403) {
    notifications.error(response.data.message);
  }

  return Promise.reject(error);
});

const mergeParams = (params = {}) => ({ ...params });

const http = {
  get: (url, params) => (agent.request({
    url,
    method: 'get',
    params: mergeParams(params),
  })),
  post: (url, params) => (agent.request({
    url,
    method: 'post',
    headers: defaultHeaders,
    data: mergeParams(params),
  })),
  put: (url, params) => (agent.request({
    url,
    method: 'put',
    headers: defaultHeaders,
    data: mergeParams(params),
  })),
  delete: (url, params) => (agent.request({
    url,
    method: 'delete',
    headers: defaultHeaders,
    params: mergeParams(params),
  })),
};

export { csrfToken };

export default http;
