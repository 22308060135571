import React from 'react';

import { FormGroup, FormControl, HelpBlock } from 'react-bootstrap';

const StaticFieldGroup = ({ id, errorMessage, ...props }) => {
  const validationState = errorMessage ? 'error' : null;

  return (
    <FormGroup controlId={id} validationState={validationState}>
      <FormControl.Static>{props.defaultValue}</FormControl.Static>
      {errorMessage && <HelpBlock>{errorMessage}</HelpBlock>}
    </FormGroup>
  );
};

export default StaticFieldGroup;
