import ConstantMessage from './constantMessage';
import Base from './base';

const aroundComponents = {
  Base,
  ConstantMessage,
};

export { Base, ConstantMessage };
export default aroundComponents;
